var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-container"},[(_vm.dialog.refresh)?_c('Operate',{attrs:{"dialog":_vm.dialog.show,"row":_vm.dialog.row,"info":_vm.dialog.info},on:{"update:dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"get-list":_vm.getList,"refresh":_vm.refreshDialog}}):_vm._e(),(_vm.dialog.refreshP)?_c('Permission',{attrs:{"dialog":_vm.dialog.showP,"row":_vm.dialog.row,"info":_vm.dialog.info},on:{"update:dialog":function($event){return _vm.$set(_vm.dialog, "showP", $event)},"get-list":_vm.getList,"refresh":_vm.refreshDialog}}):_vm._e(),_c('el-card',[_c('el-row',{attrs:{"type":"flex","justify":"space-between","gutter":50}},[(_vm.$companyType == 0)?_c('el-col',[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("地区")]),_c('el-cascader',{ref:"area",staticClass:"input searchInput",attrs:{"options":_vm.region,"value-key":"id","filterable":"","clearable":"","size":"mini","show-all-levels":true,"props":{
                        lazy: _vm.$admin,
                        lazyLoad: _vm.lazyRegion,
                        value: 'id',
                        label: 'areaName',
                        children: 'children',
                        checkStrictly: true,
                        expandTrigger: 'hover',
                    }},on:{"change":_vm.changeArea},model:{value:(_vm.condition.region),callback:function ($$v) {_vm.$set(_vm.condition, "region", $$v)},expression:"condition.region"}})],1):_vm._e(),(_vm.$admin)?_c('el-col',[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("公司类型")]),_c('el-select',{staticClass:"input searchInput",attrs:{"clearable":"","size":"mini"},on:{"change":_vm.changeCondition},model:{value:(_vm.condition.companyType),callback:function ($$v) {_vm.$set(_vm.condition, "companyType", $$v)},expression:"condition.companyType"}},_vm._l((_vm.companyType),function(v,i){return _c('el-option',{key:v.id,attrs:{"value":v.dictCode,"label":v.dictName}})}),1)],1):_vm._e(),_c('el-col',[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("行业")]),_c('el-select',{staticClass:"input searchInput",attrs:{"clearable":"","size":"mini"},on:{"change":_vm.changeCondition},model:{value:(_vm.condition.industry),callback:function ($$v) {_vm.$set(_vm.condition, "industry", $$v)},expression:"condition.industry"}},_vm._l((_vm.industry),function(v,i){return _c('el-option',{key:v.id,attrs:{"value":v.dictCode,"label":v.dictName}})}),1)],1),_c('el-col',[_c('el-input',{staticClass:"input searchInput",attrs:{"size":"mini","suffix-icon":"el-icon-search","placeholder":"搜索"},on:{"input":_vm.changeCondition},model:{value:(_vm.condition.search),callback:function ($$v) {_vm.$set(_vm.condition, "search", $$v)},expression:"condition.search"}})],1)],1)],1),_c('el-card',{staticStyle:{"margin-top":"20px"}},[_c('el-row',[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('el-row',{attrs:{"type":"flex","justify":"between"}},[_c('el-col',[(
                                    _vm.$hasBtn(
                                        'sys:permissions:org:company:add'
                                    )
                                )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.openDialog({
                                        title: '添加公司',
                                        addOr: 'add',
                                    })}}},[_vm._v("添加公司")]):_vm._e()],1),_c('el-col',{attrs:{"span":1}},[_c('vxe-button',{on:{"click":_vm.getList}},[_vm._v("刷新")])],1)],1)]},proxy:true}])}),_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyle,"align":"center","height":"600","row-id":"id","sort-config":{ remote: true },"filter-config":{ remote: true },"checkbox-config":{ reserve: true },"data":_vm.table.list,"loading":_vm.loading}},[_c('vxe-table-column',{attrs:{"type":"checkbox","width":"60","fixed":"left"}}),_c('vxe-table-column',{attrs:{"type":"seq","width":"60"}}),_c('vxe-table-column',{attrs:{"field":"companyName","title":"公司名称","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"companyAddress","title":"公司地点","show-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"contactName","title":"联系人"}}),_c('vxe-table-column',{attrs:{"field":"contactPhone","title":"联系电话"}}),_c('vxe-table-column',{attrs:{"title":"所属地区","show-overflow":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [_c('span',[_vm._v(_vm._s(("" + (row.provinceName || "") + (row.cityName ? "/" + row.cityName : "") + (row.areaName ? "/" + row.areaName : ""))))])]}}])}),_c('vxe-table-column',{attrs:{"title":"公司类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [(row.companyType == 0)?_c('span',[_vm._v("行管公司")]):_c('span',[_vm._v("普通公司")])]}}])}),_c('vxe-table-column',{attrs:{"field":"industryName","title":"行业类型"}}),_c('vxe-table-column',{attrs:{"title":"是否签名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
return [(row.isSign == 0)?_c('span',[_vm._v("是")]):_c('span',[_vm._v("否")])]}}])}),(_vm.$hasBtn('sys:permissions:org:company:status'))?_c('vxe-table-column',{attrs:{"field":"status","title":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var row = ref.row;
                                    var rowIndex = ref.rowIndex;
return [_c('el-switch',{ref:"switch",attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-value":0,"inactive-value":1},on:{"change":function (v) {
                                    _vm.changeStatus(v, row);
                                }},model:{value:(row.status),callback:function ($$v) {_vm.$set(row, "status", $$v)},expression:"row.status"}})]}}],null,false,4229920936)}):_vm._e(),_c('vxe-table-column',{attrs:{"field":"","title":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
                                var rowIndex = ref.rowIndex;
return [_c('el-dropdown',{attrs:{"size":"small","type":"primary"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v(" 操作"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                                        _vm.$hasBtn(
                                            'sys:permissions:org:company:edit'
                                        )
                                    )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-edit"},nativeOn:{"click":function($event){return _vm.openDialog(
                                            {
                                                title: '修改公司',
                                                addOr: 'upd',
                                            },
                                            row
                                        )}}},[_vm._v("修改")]):_vm._e(),(
                                        _vm.$hasBtn(
                                            'sys:permissions:org:company:permissions'
                                        )
                                    )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-link"},nativeOn:{"click":function($event){return _vm.openDialog2(
                                            {
                                                title: '分配web权限',
                                                addOr: 'permi',
                                            },
                                            row
                                        )}}},[_vm._v("分配web权限")]):_vm._e(),(
                                        _vm.$hasBtn(
                                            'sys:permissions:org:company:permissions'
                                        )
                                    )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-link"},nativeOn:{"click":function($event){return _vm.openDialog2(
                                            {
                                                title: '分配app权限',
                                                addOr: 'permiApp',
                                            },
                                            row
                                        )}}},[_vm._v("分配app权限")]):_vm._e(),(
                                        _vm.$hasBtn(
                                            'sys:permissions:org:company:jobs'
                                        )
                                    )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-link"},nativeOn:{"click":function($event){return _vm.openDialog2(
                                            {
                                                title: '分配岗位',
                                                addOr: 'posi',
                                            },
                                            row
                                        )}}},[_vm._v("分配岗位")]):_vm._e(),(
                                        _vm.$hasBtn(
                                            'sys:permissions:org:company:role'
                                        )
                                    )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-link"},nativeOn:{"click":function($event){return _vm.openDialog2(
                                            {
                                                title: '分配角色',
                                                addOr: 'role',
                                            },
                                            row
                                        )}}},[_vm._v("分配角色")]):_vm._e(),(
                                        _vm.$hasBtn(
                                            'sys:permissions:org:company:delete'
                                        )
                                    )?_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"},nativeOn:{"click":function($event){return _vm.remove({ del: 'single' }, row)}}},[_vm._v("删除")]):_vm._e()],1)],1)]}}])})],1),_c('div',{staticStyle:{"height":"20px"}}),_c('el-pagination',{attrs:{"background":"","current-page":_vm.table.currentPage,"page-sizes":[5, 10, 15, 20, 50, 100],"page-size":_vm.table.pageSize,"layout":"total, ->, sizes, prev, pager, next, jumper","total":_vm.table.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }