<template>
    <el-dialog
        :visible.sync="show"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <div slot="title" class="header-title">
            <el-row :gutter="5">
                <el-col :span="24">
                    <span class="title-name">{{ info.title }}</span>
                </el-col>
            </el-row>
        </div>
        <el-row :gutter="0">
            <el-col :span="24">
                <el-form ref="ruleForm" label-width="140px" class="allForm">
                    <el-form-item label="公司名称" :required="true">
                        <el-input
                            v-model="form.companyName"
                            class="formInput"
                        />
                    </el-form-item>
                    <el-form-item label="联系人姓名" :required="true">
                        <el-input
                            v-model="form.contactName"
                            class="formInput"
                        />
                    </el-form-item>
                    <el-form-item label="联系人电话" :required="true">
                        <el-input
                            v-model="form.contactPhone"
                            class="formInput"
                            maxlength="11"
                        />
                    </el-form-item>
                    <el-form-item label="公司地址" :required="true">
                        <el-input
                            v-model="form.companyAddress"
                            class="formInput"
                            maxlength="30"
                        />
                    </el-form-item>
                    <el-form-item
                        v-if="$admin || $companyType == 0"
                        label="公司类型"
                        :required="true"
                        class="formInput"
                    >
                        <el-select
                            v-model="form.companyType"
                            class="w-100"
                            :disabled="info.addOr === 'upd'"
                        >
                            <el-option
                                v-for="v in companyType"
                                :key="v.id"
                                :value="v.dictCode"
                                :label="v.dictName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        v-show="form.companyType == 0"
                        label="公司"
                        :required="true"
                        class="formInput companyNameAll"
                    >
                        <div class="companyNameList">
                            <!-- <div class="companyNameAllList">
								<el-tree :data="allCity" show-checkbox node-key="id" :props="defaultProps" @check="treeChange" />
							</div> -->
                            <div>
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="filterText"
                                    @input="citySearch"
                                    style="width: 260px; height: 40px"
                                >
                                </el-input>
                                <div class="companyNameAllListNow">
                                    <el-tree
                                        :data="allCity"
                                        show-checkbox
                                        node-key="areaCode"
                                        :props="defaultProps"
                                        class="filter-tree"
                                        ref="tree"
                                        @check="treeChange"
                                        @check-change="treeChangeClick"
                                    />
                                </div>
                                <div
                                    class="flex-row-box flex-wrap"
                                    style="width: 260px"
                                >
                                    <div
                                        v-for="(item, index) in cityName"
                                        :key="index"
                                    >
                                        <div
                                            v-if="
                                                cityName && cityName.length > 0
                                            "
                                            class="pl-1"
                                        >
                                            {{ item }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-input
                                    @change="getCompanyListAll"
                                    placeholder="请输入内容"
                                    v-model="companyNameNow"
                                    style="width: 260px; height: 40px"
                                >
                                    <i
                                        slot="prefix"
                                        class="el-input__icon el-icon-search"
                                    ></i>
                                </el-input>
                                <div class="companyNameAllListNow">
                                    <el-checkbox
                                        :indeterminate="isIndeterminate"
                                        v-model="sonIdsAll"
                                        @change="handleCheckAllChange"
                                        >全选</el-checkbox
                                    >
                                    <el-checkbox-group
                                        v-model="sonIds"
                                        size="medium"
                                        @change="changeCascaderAll"
                                    >
                                        <el-checkbox
                                            v-for="v in company"
                                            :key="v.companyId"
                                            :label="v.companyId"
                                            style="width: 260px"
                                            >{{ v.companyName }}</el-checkbox
                                        >
                                    </el-checkbox-group>
                                </div>
                                <div
                                    v-if="
                                        form.companyIds &&
                                        form.companyIds.length
                                    "
                                >
                                    已选择{{ form.companyIds.length }}家企业
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item
                        label="行业"
                        :required="true"
                        class="formInput"
                    >
                        <el-select v-model="form.industry" class="w-100">
                            <el-option
                                v-for="(v, i) in industry"
                                :key="v.id"
                                :value="v.dictCode"
                                :label="v.dictName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="是否签名"
                        :required="true"
                        class="formInput"
                    >
                        <el-select v-model="form.isSign" class="w-100">
                            <el-option
                                v-for="(v, i) in sign"
                                :key="v.id"
                                :value="v.dictCode"
                                :label="v.dictName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="地区"
                        :required="true"
                        class="formInput"
                    >
                        <el-cascader
                            ref="area"
                            v-model="regionCode"
                            :options="areaCode"
                            value-key="id"
                            filterable
                            clearable
                            class="w-100"
                            :props="{
                                lazy: false,
                                lazyLoad: lazyRegion,
                                value: 'areaCode',
                                label: 'areaName',
                                children: 'children',
                                checkStrictly: form.companyType == 0,
                                emitPath: true,
                            }"
                            @change="changeArea"
                        />
                    </el-form-item>
                    <el-form-item
                        label="车辆三检是否补检"
                        :required="false"
                        class="formInput"
                    >
                        <GlobalDictSelect
                            parentCode="TRUE_FALSE"
                            v-model="form.isCheck"
                            style="width: 100%"
                        >
                        </GlobalDictSelect>
                    </el-form-item>

                    <el-form-item label="企业logo" :required="false">
                        <div class="upload-logo">
                            <div
                                style="padding-right: 100px"
                                v-if="fileList.length > 0"
                                class="img-item"
                            >
                                <img
                                    :src="fileUrl + fileList[0].filePath"
                                    style="
                                        width: 210px;
                                        height: 80px;
                                        border-radius: 4px;
                                    "
                                />
                                <div class="del-box">
                                    <div class="icon-box">
                                        <i
                                            class="el-icon-delete"
                                            style="
                                                color: #ffffff;
                                                font-size: 22px;
                                                cursor: pointer;
                                            "
                                            @click="fileList = []"
                                        ></i>
                                    </div>
                                </div>
                            </div>

                            <GlobalElUpload
                                v-else
                                v-model="fileList"
                                :limit="1"
                            ></GlobalElUpload>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        请使用小于1MB的png格式图片，推荐尺寸：210*80像素
                    </el-form-item>
                    <el-form-item label="企业Icon" :required="false">
                        <GlobalElUpload
                            v-model="fileList2"
                            accept=".ico"
                            :limit="1"
                        ></GlobalElUpload>
                    </el-form-item>
                    <el-form-item> 请使用小于1MB的icon格式图片 </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="commit">提交</el-button>
            <el-button type="info" @click="close">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { fileUrl } from "@/api/Global";
import {
    getAreaChildren,
    getDictParentCode,
    company,
    getAreaTree,
    getAreaTreeByCode,
    areaTreeAll,
    companyAll,
} from "@/api";
export default {
    name: "Operate",
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            fileUrl,
            show: this.dialog,
            form: {
                companyName: "",
                contactName: "",
                contactPhone: "",
                companyAddress: "",
                companyType: "1",
                areaCode: "",
                cityCode: "",
                provinceCode: "",
                industry: "",
                isSign: "0",
                parentId: "",
                logoUrl: "", // logo
                iconUrl: "", // icon
                sonIds: [],
                isCheck: "",
            },
            fileList: [], // 企业logo 图片列表
            fileList2: [], // 企业icon
            sonIds: [],
            regionCode: [],
            areaCode: [],
            industry: [],
            companyType: [],
            companyList: [],
            sign: [],
            $loginInfo: {},
            defaultProps: {
                children: "children",
                label: "areaName",
            },
            allCity: [],
            checkboxGroup: [],
            company: [],
            cityCode: [],
            isIndeterminate: false,
            companyNameNow: "",
            sonIdsAll: [],
            filterText: "",
            check: false,
            cityName: [],
        };
    },
    created() {
        this.getAreaTreeAll();
        this.getCompanyListAll();
        this.getDictParentCode();
        this.getIsSign();
        this.getCompanyType();
        this.getCompanyList();
        if (!this.$admin) this.getAreaChildren();
        else this.getAreaTree();
    },
    mounted() {
        this.$nextTick(() => {
            this.upd();
        });
    },
    methods: {
        citySearch() {
            var value = this.filterText;
            if (value) {
                var list = this.allCity;
                this.allCity = [];
                var isVal = null;
                var isVal1 = null;
                var isVal2 = null;
                for (let i = 0; i < list.length; i++) {
                    isVal = list[i].areaName.indexOf(value);
                    if (isVal == -1) {
                        var vv = list[i];
                        if (vv.children) {
                            for (let f = 0; f < vv.children.length; f++) {
                                isVal1 = vv.children[f].areaName.indexOf(value);
                                if (isVal1 == -1) {
                                    var cc = vv.children[f];
                                    if (cc.children) {
                                        for (
                                            let e = 0;
                                            e < cc.children.length;
                                            e++
                                        ) {
                                            isVal2 = cc.children[
                                                e
                                            ].areaName.indexOf(value);
                                            if (isVal2 != -1) {
                                                //console.log(cc.children[e])
                                                this.allCity.push(
                                                    cc.children[e]
                                                );
                                            }
                                        }
                                    }
                                } else {
                                    // //console.log(list[i].children[f].children)
                                    this.allCity.push(vv.children[f]);
                                }
                            }
                        }
                    } else {
                        this.allCity.push(list[i]);
                    }
                }
            } else {
                this.getAreaTreeAll();
            }
        },
        getAreaTreeAll() {
            this.loading = true;
            this.$axiosReq(areaTreeAll, null, null, "get")
                .then((res) => {
                    this.allCity = res.data;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getCompanyListAll() {
            var that_ = this;
            this.$axiosReq(
                companyAll + "/" + that_.companyNameNow,
                {
                    areaCodes: that_.cityCode,
                },
                null,
                "post"
            ).then((res) => {
                that_.company = res.data;
            });
        },
        changeCascaderAll(data) {
            // this.sonIds = data;
            this.sonIdsAll = this.sonIds;
        },
        handleCheckAllChange(v) {
            var data = this.company;
            if (v) {
                var index = null;
                for (let i = 0; i < data.length; i++) {
                    index = this.sonIds.indexOf(data[i].companyId);
                    if (index == "-1") {
                        this.sonIds.push(data[i].companyId);
                    }
                }
            } else {
                for (let i = 0; i < data.length; i++) {
                    this.sonIds = this.sonIds.filter(
                        (item) => item != data[i].companyId
                    );
                }
            }
            this.isIndeterminate = false;
        },
        treeChangeClick(data, check) {
            this.check = check;
        },
        treeChange(data) {
            if (this.check) {
                this.$refs.tree.setCheckedKeys([data.areaCode]);
                if (data.level == 3) {
                    this.cityCode = [];
                    this.cityCode.push(data.areaCode);
                    this.cityName.push(data.areaName);
                } else if (data.level == 2) {
                    var dataAll = data.children;
                    this.cityCode = [];
                    for (let i = 0; i < dataAll.length; i++) {
                        this.cityCode.push(dataAll[i].areaCode);
                        this.cityName.push(dataAll[i].areaName);
                    }
                } else if (data.level == 1) {
                    var dataAll = data.children;
                    this.cityCode = [];
                    for (let i = 0; i < dataAll.length; i++) {
                        for (let f = 0; f < dataAll[i].children.length; f++) {
                            this.cityCode.push(dataAll[i].children[f].areaCode);
                            this.cityName.push(dataAll[i].children[f].areaName);
                        }
                    }
                }
                this.getCompanyListAll();
            } else {
                if (data.level == 3) {
                    this.cityCode = this.cityCode.filter(
                        (item) => item != data.areaCode
                    );
                    this.cityName = this.cityName.filter(
                        (item) => item != data.areaName
                    );
                } else if (data.level == 2) {
                    var dataAll = data.children;
                    for (let i = 0; i < dataAll.length; i++) {
                        this.cityCode = this.cityCode.filter(
                            (item) => item != dataAll[i].areaCode
                        );
                        this.cityName = this.cityName.filter(
                            (item) => item != dataAll[i].areaName
                        );
                    }
                } else if (data.level == 1) {
                    var dataAll = data.children;
                    for (let i = 0; i < dataAll.length; i++) {
                        for (let f = 0; f < dataAll[i].children.length; f++) {
                            this.cityCode = this.cityCode.filter(
                                (item) =>
                                    item != dataAll[i].children[f].areaCode
                            );
                            this.cityName = this.cityName.filter(
                                (item) =>
                                    item != dataAll[i].children[f].areaName
                            );
                        }
                    }
                }
                this.getCompanyListAll();
            }
        },
        unique(arr) {
            const res = new Map();
            return arr.filter(
                (arr) => !res.has(arr.companyId) && res.set(arr.companyId, 1)
            );
        },
        upd() {
            if (this.info.addOr === "upd") {
                this.$axiosReq(
                    company + "/" + this.row.id,
                    null,
                    null,
                    "get"
                ).then((res) => {
                    this.form = res.data;
                    this.form.status = null;
                    this.form.industry = res.data.industry;
                    this.form.companyType = res.data.companyType + "";
                    this.form.isSign = res.data.isSign + "";
                    this.sonIds = res.data.sonIds;
                    this.sonIdsAll = res.data.sonIds;
                    this.regionCode = [
                        res.data.provinceCode,
                        res.data.cityCode,
                        res.data.areaCode,
                    ];

                    if (res.data.logoUrl) {
                        this.fileList = [
                            {
                                filePath: res.data.logoUrl,
                                url: this.fileUrl + res.data.logoUrl,
                            },
                        ];
                    }
                    if (res.data.iconUrl) {
                        this.fileList2 = [
                            {
                                filePath: res.data.iconUrl,
                                url: this.fileUrl + res.data.iconUrl,
                            },
                        ];
                    }

                    this.regionCode = this.regionCode.filter((v) => {
                        return v;
                    });
                });
            }
        },
        getAreaTree() {
            this.$axiosReq(getAreaTree, null, null, "get")
                .then((res) => {
                    this.areaCode = res.data;
                })
                .finally((e) => {});
        },
        getAreaChildren() {
            this.loading = true;
            this.$axiosReq(
                getAreaTreeByCode + this.$provinceCode,
                null,
                null,
                "get"
            )
                .then((res) => {
                    this.areaCode = res.data;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        lazyRegion(v, resolve) {
            // 异步加载子节点
            this.$axiosReq(
                getAreaChildren,
                null,
                {
                    parentId: v.value,
                },
                "get"
            ).then((res) => {
                const arr = [];
                for (const i of res.data) {
                    arr.push({
                        ...i,
                        hasChild: i.level < 3,
                    });
                }
                resolve(arr);
            });
        },
        getDictParentCode() {
            this.loading = true;
            this.$axiosReq(
                getDictParentCode + "INDUSTRY_TYPE",
                null,
                null,
                "get"
            )
                .then((res) => {
                    this.industry = res.data;
                })
                .finally((res) => {
                    this.loading = false;
                });
        },
        getIsSign() {
            this.loading = true;
            this.$axiosReq(getDictParentCode + "TRUE_FALSE", null, null, "get")
                .then((res) => {
                    this.sign = res.data;
                })
                .finally((res) => {
                    this.loading = false;
                });
        },
        getCompanyType() {
            this.loading = true;
            this.$axiosReq(
                getDictParentCode + "COMPANY_TYPE",
                null,
                null,
                "get"
            )
                .then((res) => {
                    this.companyType = res.data;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getCompanyList() {
            this.loading = true;
            this.$axiosReq(
                company,
                null,
                {
                    pageSize: 10000,
                },
                "get"
            )
                .then((res) => {
                    this.companyList = res.data.content;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        changeArea(v) {
            const node = this.$refs.area.getCheckedNodes();
            if (v.length == 1) {
                this.form.provinceCode = v[0];
                this.form.cityCode = "";
                this.form.areaCode = "";
            } else if (v.length == 2) {
                this.form.provinceCode = v[0];
                this.form.cityCode = v[1];
                this.form.areaCode = "";
            } else if (v.length == 3) {
                this.form.provinceCode = v[0];
                this.form.cityCode = v[1];
                this.form.areaCode = v[2];
            } else {
                this.form.provinceCode = "";
                this.form.cityCode = "";
                this.form.areaCode = "";
            }
        },
        // 提交添加
        commit() {
            if (this.$textNull(this.form.companyName)) {
                this.$message.error("请填写公司名称！");
                return;
            } else if (this.$textNull(this.form.contactName)) {
                this.$message.error("请填写联系人名称！");
                return;
            } else if (this.$textNull(this.form.contactPhone)) {
                this.$message.error("请填写联系人电话！");
                return;
            } else if (this.$textNull(this.form.companyAddress)) {
                this.$message.error("请填写公司地址！");
                return;
            } else if (this.form.companyType == 0) {
                if (this.sonIds.length === 0) {
                    this.$message.error("请选择公司！");
                    return;
                }
            } else if (this.$textNull(this.form.industry)) {
                this.$message.error("请选择行业！");
                return;
            } else if (this.$textNull(this.regionCode)) {
                this.$message.error("请选择地区！");
                return;
            }
            this.form.permissionsVoList = null;
            this.form.positionVoList = null;
            this.form.roleVoList = null;

            if (!this.$admin) {
                this.form.parentId = this.$companyId;
            }
            if (this.form.companyType == 1) {
                this.form.sonIds = [];
                this.sonIds = [];
            }
            // 添加企业logo
            if (Array.isArray(this.fileList) && this.fileList.length > 0) {
                this.form.logoUrl = this.fileList[0].filePath;
            } else {
                this.form.logoUrl = "";
            }

            if (Array.isArray(this.fileList2) && this.fileList2.length > 0) {
                this.form.iconUrl = this.fileList2[0].filePath;
            } else {
                this.form.iconUrl = "";
            }

            if (this.info.addOr === "add") {
                this.form.sonIds = this.sonIds;
                this.$axiosReq(company, this.form, null, "post").then((res) => {
                    this.$message.success(res.msg);
                    this.close();
                    this.$emit("get-list");
                });
            } else {
                this.form.id = null;
                this.form.sonIds = this.sonIds;
                // this.form.sonIds = this.sonIds.toString()
                this.$axiosReq(
                    company + "/" + this.row.id,
                    this.form,
                    null,
                    "put"
                ).then((res) => {
                    this.$message.success(res.msg);
                    this.close();
                    this.$emit("get-list");
                });
            }
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
    },
};
</script>

<style scoped>
.companyNameList {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.companyNameAllList {
    width: 260px;
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    border: 1px solid #d9d8d3;
    padding: 5px;
    margin-right: 20px;
}

.companyNameAllList::-webkit-scrollbar {
    display: none;
}

.companyNameAllListNow {
    width: 260px;
    height: 160px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    border: 1px solid #d9d8d3;
    padding: 5px;
    margin-right: 20px;
}

.companyNameAllListNow::-webkit-scrollbar {
    display: none;
}

.upload-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.img-item {
    width: 210px;
    height: 80px;
    position: relative;
    border-radius: 4px;
}
.img-item:hover .del-box {
    width: 210px;
    height: 80px;
    display: inline;
    position: absolute;
    z-index: 8;
    top: 0;
    border-radius: 4px;
    background-color: #060a0e82;
}
.del-box {
    display: none;
}
.icon-box {
    width: 210px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}
</style>
