<template>
    <div class="dashboard-container">
        <Operate
            v-if="dialog.refresh"
            :dialog.sync="dialog.show"
            :row="dialog.row"
            :info="dialog.info"
            @get-list="getList"
            @refresh="refreshDialog"
        />
        <Permission
            v-if="dialog.refreshP"
            :dialog.sync="dialog.showP"
            :row="dialog.row"
            :info="dialog.info"
            @get-list="getList"
            @refresh="refreshDialog"
        />
        <el-card>
            <el-row type="flex" justify="space-between" :gutter="50">
                <el-col v-if="$companyType == 0">
                    <span class="text-primary text-pad-right">地区</span>
                    <el-cascader
                        ref="area"
                        v-model="condition.region"
                        :options="region"
                        value-key="id"
                        filterable
                        clearable
                        size="mini"
                        class="input searchInput"
                        :show-all-levels="true"
                        :props="{
                            lazy: $admin,
                            lazyLoad: lazyRegion,
                            value: 'id',
                            label: 'areaName',
                            children: 'children',
                            checkStrictly: true,
                            expandTrigger: 'hover',
                        }"
                        @change="changeArea"
                    />
                </el-col>
                <el-col v-if="$admin">
                    <span class="text-primary text-pad-right">公司类型</span>
                    <el-select
                        v-model="condition.companyType"
                        class="input searchInput"
                        clearable
                        size="mini"
                        @change="changeCondition"
                    >
                        <el-option
                            v-for="(v, i) in companyType"
                            :key="v.id"
                            :value="v.dictCode"
                            :label="v.dictName"
                        />
                    </el-select>
                </el-col>
                <el-col>
                    <span class="text-primary text-pad-right">行业</span>
                    <el-select
                        v-model="condition.industry"
                        class="input searchInput"
                        clearable
                        size="mini"
                        @change="changeCondition"
                    >
                        <el-option
                            v-for="(v, i) in industry"
                            :key="v.id"
                            :value="v.dictCode"
                            :label="v.dictName"
                        />
                    </el-select>
                </el-col>
                <el-col>
                    <el-input
                        v-model="condition.search"
                        class="input searchInput"
                        size="mini"
                        suffix-icon="el-icon-search"
                        placeholder="搜索"
                        @input="changeCondition"
                    />
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 20px">
            <el-row>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <el-row type="flex" justify="between">
                            <el-col>
                                <el-button
                                    v-if="
                                        $hasBtn(
                                            'sys:permissions:org:company:add'
                                        )
                                    "
                                    type="primary"
                                    size="mini"
                                    @click="
                                        openDialog({
                                            title: '添加公司',
                                            addOr: 'add',
                                        })
                                    "
                                    >添加公司</el-button
                                >
                                <!--                <el-button type="danger" size="mini" @click="remove({del:'batch'})">批量删除</el-button>-->
                            </el-col>
                            <el-col :span="1">
                                <vxe-button @click="getList">刷新</vxe-button>
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-table
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="600"
                    row-id="id"
                    :sort-config="{ remote: true }"
                    :filter-config="{ remote: true }"
                    :checkbox-config="{ reserve: true }"
                    :data="table.list"
                    :loading="loading"
                >
                    <vxe-table-column type="checkbox" width="60" fixed="left" />
                    <vxe-table-column type="seq" width="60" />
                    <vxe-table-column
                        field="companyName"
                        title="公司名称"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="companyAddress"
                        title="公司地点"
                        show-overflow="title"
                    />
                    <vxe-table-column field="contactName" title="联系人" />
                    <vxe-table-column field="contactPhone" title="联系电话" />
                    <vxe-table-column title="所属地区" show-overflow="title">
                        <template v-slot="{ row }">
                            <span>{{
                                `${row.provinceName || ""}${
                                    row.cityName ? "/" + row.cityName : ""
                                }${row.areaName ? "/" + row.areaName : ""}`
                            }}</span>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="公司类型">
                        <template v-slot="{ row }">
                            <span v-if="row.companyType == 0">行管公司</span>
                            <span v-else>普通公司</span>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="industryName" title="行业类型" />
                    <vxe-table-column title="是否签名">
                        <template v-slot="{ row }">
                            <span v-if="row.isSign == 0">是</span>
                            <span v-else>否</span>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column
                        v-if="$hasBtn('sys:permissions:org:company:status')"
                        field="status"
                        title="状态"
                    >
                        <template v-slot="{ row, rowIndex }">
                            <el-switch
                                ref="switch"
                                v-model="row.status"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                :active-value="0"
                                :inactive-value="1"
                                @change="
                                    (v) => {
                                        changeStatus(v, row);
                                    }
                                "
                            />
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="" title="操作">
                        <template v-slot="{ row, rowIndex }">
                            <el-dropdown size="small" type="primary">
                                <el-button size="small" type="primary">
                                    操作<i
                                        class="el-icon-arrow-down el-icon--right"
                                    />
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:company:edit'
                                            )
                                        "
                                        icon="el-icon-edit"
                                        @click.native="
                                            openDialog(
                                                {
                                                    title: '修改公司',
                                                    addOr: 'upd',
                                                },
                                                row
                                            )
                                        "
                                        >修改</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:company:permissions'
                                            )
                                        "
                                        icon="el-icon-link"
                                        @click.native="
                                            openDialog2(
                                                {
                                                    title: '分配web权限',
                                                    addOr: 'permi',
                                                },
                                                row
                                            )
                                        "
                                        >分配web权限</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:company:permissions'
                                            )
                                        "
                                        icon="el-icon-link"
                                        @click.native="
                                            openDialog2(
                                                {
                                                    title: '分配app权限',
                                                    addOr: 'permiApp',
                                                },
                                                row
                                            )
                                        "
                                        >分配app权限</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:company:jobs'
                                            )
                                        "
                                        icon="el-icon-link"
                                        @click.native="
                                            openDialog2(
                                                {
                                                    title: '分配岗位',
                                                    addOr: 'posi',
                                                },
                                                row
                                            )
                                        "
                                        >分配岗位</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:company:role'
                                            )
                                        "
                                        icon="el-icon-link"
                                        @click.native="
                                            openDialog2(
                                                {
                                                    title: '分配角色',
                                                    addOr: 'role',
                                                },
                                                row
                                            )
                                        "
                                        >分配角色</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:company:delete'
                                            )
                                        "
                                        icon="el-icon-delete"
                                        @click.native="
                                            remove({ del: 'single' }, row)
                                        "
                                        >删除</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <div style="height: 20px" />
                <el-pagination
                    background
                    :current-page="table.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100]"
                    :page-size="table.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-row>
        </el-card>
    </div>
</template>

<script>
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import {
    company,
    companyStatus,
    getAreaChildren,
    getDictParentCode,
    getAreaTreeByCode,
    areaParentCode,
} from "@/api";
import Operate from "./Operate";
import Permission from "./Permission";

export default {
    name: "Index",
    components: {
        Operate,
        Permission,
    },
    data() {
        return {
            loading: false,
            condition: {
                areaCode: {},
                // cityCode: null,
                // provinceCode: null,
                searchName: null,
                companyType: "",
                industry: "",
                currentPage: 1,
                pageSize: 10,
                direction: "",
                provinceCode: "",
                cityCode: "",
                areaCode: "",
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                refreshP: false, // 每次刷新DOM
                show: false,
                showP: false,
            },
            region: [],
            industry: [],
            companyType: [],
            loginInfo: {},
        };
    },
    created() {
        this.getList();
        this.getIndustry();
        this.getCompanyType();
        if (!this.$admin) this.getAreaChildren();
    },
    methods: {
        rowStyle,
        headerStyle,
        getAreaChildren() {
            this.loading = true;
            this.$axiosReq(
                getAreaTreeByCode + this.$regionCode,
                null,
                null,
                "get"
            )
                .then((res) => {
                    this.region = res.data;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        lazyRegion(v, resolve) {
            // 异步加载子节点
            this.$axiosReq(
                getAreaChildren,
                null,
                {
                    parentId: v.value,
                },
                "get"
            ).then((res) => {
                const arr = [];
                for (const i of res.data) {
                    arr.push({
                        ...i,
                        hasChild: i.level < 3,
                    });
                }
                resolve(arr);
            });
        },
        getIndustry() {
            this.loading = true;
            this.$axiosReq(
                getDictParentCode + "INDUSTRY_TYPE",
                null,
                null,
                "get"
            )
                .then((res) => {
                    this.industry = res.data;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getCompanyType() {
            this.loading = true;
            this.$axiosReq(
                getDictParentCode + "COMPANY_TYPE",
                null,
                null,
                "get"
            )
                .then((res) => {
                    this.companyType = res.data;
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        }, // 打开dialog
        openDialog2(info, row) {
            this.dialog.refreshP = true;
            this.dialog.showP = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        changeCondition(v) {
            this.table.currentPage = 1;
            this.getList();
        },
        changeArea(v) {
            const node = this.$refs.area.getCheckedNodes();
            //console.log(node);
            if (node.length > 0) {
                if (node[0].data.level === 1) {
                    this.condition.provinceCode = node[0].data.areaCode;
                    this.condition.cityCode = "";
                    this.condition.areaCode = "";
                } else if (node[0].data.level === 2) {
                    this.condition.provinceCode = "";
                    this.condition.cityCode = node[0].data.areaCode;
                    this.condition.areaCode = "";
                } else if (node[0].data.level === 3) {
                    this.condition.provinceCode = "";
                    this.condition.cityCode = "";
                    this.condition.areaCode = node[0].data.areaCode;
                }
            } else {
                this.condition.provinceCode = "";
                this.condition.cityCode = "";
                this.condition.areaCode = "";
            }
            this.getList();
        },
        getList() {
            this.loading = true;
            this.$axiosReq(
                company,
                null,
                {
                    provinceCode: this.condition.provinceCode,
                    cityCode: this.condition.cityCode,
                    areaCode: this.condition.areaCode,
                    industry: this.condition.industry,
                    searchName: this.condition.search,
                    companyType: this.condition.companyType,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(Number(res.data.totalElements));
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        changeStatus(v, row) {
            this.$axiosReq(companyStatus + row.id, null, null, "put").then(
                (res) => {
                    this.$message.success(res.msg);
                    this.getList();
                }
            );
        },
        remove(info, row) {
            this.$confirm(
                "删除公司，将删除公司下所有相关信息，数据和信息将无法恢复！！！",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    this.$axiosReq(
                        company + "/" + row.id,
                        null,
                        null,
                        "delete"
                    ).then((res) => {
                        this.$message.success(res.msg);
                        this.$refs.table.clearCheckboxRow();
                        this.$refs.table.clearCheckboxReserve();
                        this.getList();
                    });
                })
                .catch(() => {
                    this.$message.info("取消了删除！！！");
                });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.getList();
        },
        refreshDialog() {
            this.dialog.refresh = false;
            this.dialog.refreshP = false;
        },
    },
};
</script>

<style scoped></style>
